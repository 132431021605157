<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員管理中心</b-breadcrumb-item>
            <b-breadcrumb-item active>會員等級條件</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員等級條件</h4>
      <div class="row my-3">
        <div class="col-12 col-xl-4"></div>
        <div
          class="
            col-12 col-xl-8
            d-flex
            align-items-start align-items-xl-center
            justify-content-end
          "
        >

          <b-button
            v-if="checkPermission([consts.CUSTOMER_LEVEL_RULE_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'CustomerLevelRuleCreate' }"
          ><i class="fa fa-plus"></i>新增會員等級條件</b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="levels"
            :fields="fields"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(is_enabled)="data">
              <b-badge
                :variant="
                  !!data.item.is_enabled === true ? 'success' : 'danger'
                "
                class="mr-1"
              >{{
                  !!data.item.is_enabled === true ? "啟用" : "停用"
                }}</b-badge
              >
            </template>
            <template #cell(actions)="data">
              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_RULE_LIST])"
                class="ml-2"
                variant="inverse-primary"
                :to="{
                  name: 'CustomerLevelRuleView',
                  params: { id: data.item.id },
                }"
                ><span class="mdi mdi-eye"></span> 查看</b-button
              >

              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_RULE_EDIT])"
                class="ml-2"
                variant="inverse-warning"
                :to="{
                  name: 'CustomerLevelRuleEdit',
                  params: { id: data.item.id },
                }"
                ><span class="mdi mdi-lead-pencil"></span> 編輯</b-button
              >

              <b-button
                v-if="checkPermission([consts.CUSTOMER_LEVEL_RULE_DELETE])"
                class="ml-2"
                variant="inverse-danger"
                @click="handleDelete(data.item)"
                ><span class="mdi mdi-delete"></span> 刪除</b-button
              >
            </template>
          </b-table>
        </div>
      </div>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="totalRows"
        :perPage="query.per_page"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import customerLevelRuleApi from "@/apis/customer-level-rules";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { paginationMixin } from "@/mixins/pagination";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts,
      isLoading: false,
      totalRows: 0,
      levels: [],
      fields: [
        { key: "order", label: "排序", sortable: true },
        { key: "level.name", label: "會員等級" },
        { key: "type", label: "類型" },
        { key: "is_enabled", label: "是否啟用" },
        { key: "actions", label: "管理" },
      ],
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },

  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      Object.keys(to.query).forEach((key) => {
        this.query[key] = to.query[key] || null;
      });
      this.getRuleList();
    },
  },

  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.init();
  },

  methods: {
    async init() {
      await this.getRuleList();
      this.initialized = true;
    },
    async getRuleList() {
      try {
        this.isLoading = true;

        const { data } = await customerLevelRuleApi.getRules(this.organization, {
          ...this.query,
        });

        this.levels = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleDelete(level) {
      let confirm = await this.$swal.fire({
        type: "warning",
        title: "刪除會員等級規則",
        text: `您確認要刪除 ${level.name} 這個會員等級規則嗎？`,
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        showCancelButton: true,
      });

      if (!confirm.value) return;

      try {
        await customerLevelRuleApi.removeRule(this.organization, level.id);
        this.$swal("刪除成功", "", "success");
        await this.getRuleList();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "", "error");
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style></style>
